import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import TabMenu from "../../components/Common/TabMenu";
import HomeUpcoming from "./HomeUpcoming";
import LiveNow from "./LiveNow";
import { setActiveTab, setActiveFooterTab } from "../../store/actions";
import { getLiveSportsCall } from "../../helpers/repository";
import Banners from "../../components/Common/Banners";
import SignupPopup from "../Register/SignupPopup";

function Home(props) {

  const active_tab = useSelector(
      (state) => state?.CommonState?.activeTab ?? "home-upcoming"
  );
  const Banner = useSelector((state) => state?.Banner?.Index).filter(
      (banner) => banner["page_name"] == "Home Page"
  );

  const [live_sports_count, set_live_sports_count] = useState(0);
  const [fixtures, set_fixtures] = useState([]);
  const [next_page_url, set_next_page_url] = useState(null);
  const [marketChange, setMarketChange] = useState("1");
  const dispatch = useDispatch();

  const [matchTime, setMatchTime] = useState("all");

  useEffect(() => {
    getLiveSportsCall().then((res) => {

      let sum = res?.data?.data
          .map((o) => o.live)
          .reduce((a, c) => {
            return a + c;
          });
      set_live_sports_count(sum);
    }).catch((e) => {

      if(e?.response?.data?.code=="restrict"){
        props.history.push("/comming-soon");
      }

    });
  }, []);

  const tabs = [
    {
      id: "home-upcoming",
      title: "Upcoming",
      activeClass: active_tab == "home-upcoming" ? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
    {
      id: "home-live-now",
      title: "Live Now",
      activeClass: active_tab == "home-live-now" ? "active" : "",
      tabHighlightText: `(${live_sports_count})`,
      tabHighlightId: "liveScoreCount",
    },
  ];
  return (
      <>
        <Banners Banner={Banner} />
        <TabMenu
            tabs={tabs}
            showSearch
            marketChange={marketChange}
            setMarketChange={setMarketChange}
            matchTime={matchTime}
            setMatchTime={setMatchTime}
            active_tab={active_tab}
            set_active_tab={setActiveTab}
            dispatch={dispatch}
            setActiveFooterTab={setActiveFooterTab}
            activeFooterTab={active_tab}
            fixtures={fixtures}
            set_fixtures={set_fixtures}
            next_page_url={next_page_url}
            set_next_page_url={set_next_page_url}
        />

        <div className="BLM-commonPage">
          <div className="BLM-commonPage-content-full-width BLM-common">
            <div className="BLM-tabs-content">
              {active_tab == "home-upcoming" && (
                  <HomeUpcoming
                      matchTime={matchTime}
                      setMatchTime={setMatchTime}
                      marketChange={marketChange}
                      setMarketChange={setMarketChange}
                      fixtures={fixtures}
                      set_fixtures={set_fixtures}
                      next_page_url={next_page_url}
                      set_next_page_url={set_next_page_url}
                  />
              )}
              {active_tab == "home-live-now" && (
              <LiveNow 
               marketChange={marketChange}
               setMarketChange={setMarketChange}
               />
               )}
            </div>
          </div>
        </div>
      </>
  );
}

export default withRouter(Home);
