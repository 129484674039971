import React, { useState, useEffect } from "react";
import FieldValidation from "../../components/Common/FieldValidation";
import PageHeader from "../../components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../store/Auth/actions";
import { getUserDetails } from "../../helpers/repository";
import { apiError } from "../../store/actions";

function Profile(props) {
  const [marketing_opt_in, set_marketing_opt_in] = useState("mobile");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [nin, set_nin] = useState("");

  useEffect(() => {
    dispatch(apiError(""));
    getUserDetails().then((res) => {
      set_first_name(res?.data?.data?.first_name || "");
      set_last_name(res?.data?.data?.last_name || "");
      set_email(res?.data?.data?.email || "");
      set_nin(res?.data?.data?.nin || "");
    });
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      nin: nin,
    };
    dispatch(updateProfile(data));
  };

  const formErrors = useSelector((state) => state?.CommonState);

  const removeError = (key) => {
    let e = _.cloneDeep(formErrors);
    delete e[key];
    dispatch(apiError(e));
  };
  return (
    <>
      <PageHeader title={"Profile"} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister BLM-login">
            <div className="BLM-content">
              <form onSubmit={handleSubmit}>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter First Name:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userName"
                      required
                      onChange={(e) => {
                        removeError("phone_number");
                        set_first_name(e.target.value);
                      }}
                      value={first_name}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.first_name ? (
                      <FieldValidation errorMessage={formErrors?.first_name} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter Last Name:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userLastName"
                      onChange={(e) => {
                        removeError("phone_number");
                        set_last_name(e.target.value);
                      }}
                      value={last_name}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.last_name ? (
                      <FieldValidation errorMessage={formErrors?.last_name} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Email:</div>
                  <div className="BLM-form-control">
                    <input
                      type="email"
                      id="userEmail"
                      onChange={(e) => {
                        removeError("email");
                        set_email(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.email ? (
                      <FieldValidation errorMessage={formErrors?.email} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">NIN:</div>
                  <div
                      className="BLM-form-control BLM-form-control-pin"
                      id=""
                  >
                    <input
                        type="text"
                        id="nin"
                        placeholder="Enter National Identity Number"
                        required
                        onChange={(e) => {
                          set_nin(e.target.value);
                        }}
                        maxLength={14}
                        value={nin}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.nin ? (
                        <FieldValidation
                            errorMessage={formErrors?.nin}
                        />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-radioBoxGroup">
                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="mobile"
                    id="mobile"
                    value="mobile"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "mobile"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="mobile"
                  >
                    mobile
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="online"
                    id="online"
                    value="online"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "online"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="online"
                  >
                    online
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="sms"
                    id="sms"
                    value="sms"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "sms"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="sms"
                  >
                    sms
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="telegram"
                    id="telegram"
                    value="telegram"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "telegram"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="telegram"
                  >
                    telegram
                  </label>
                </div>
                <div className="space-10" />
                <button
                  className="btn BLM-btnSecondary active BLM-btnLarge"
                  id="disableLoginButtonClick"
                  type="submit"
                >
                  <span className="BLM-btnTxt">Update Profile</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
