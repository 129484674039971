import React, { useState, useEffect } from "react";
import OpenBets from "./OpenBets";
import SettledBets from "./SettledBets";
import PageHeader from "../../components/Common/PageHeader";
import TabMenu from "../../components/Common/TabMenu";
import { BlockedGames, getMyBetsData } from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import { useSelector, useDispatch } from "react-redux";
import { setActiveFooterTab, setActiveTab } from "../../store/actions";
import { get, isEmpty } from "lodash";
import MessageBox from "../../components/Common/MessageBox";
import { getNoDataMessage } from "../../helpers/utils";

function MyBets(props) {
  const dispatch = useDispatch();

  const [category, set_category] = useState("Sports");
  const [sortCategory, set_sort_category] = useState("Latest");
  const [show, setShow] = useState(false);
  const [next_page_url, set_next_page_url] = useState(null);
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");

  const [myBets, setMyBets] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
  const [trigger, setTrigger] = useState(0);

  const active_tab = useSelector(
    (state) => state?.CommonState?.activeTab ? (state?.CommonState?.activeTab=="MyBets" ? "Open" : state?.CommonState?.activeTab) : "Open"
  );
  useEffect(() => {
    dispatch(setActiveTab("Open"));
    dispatch(setActiveFooterTab("my-bets"));
  }, []);
  useEffect(() => {
    if (!isEmpty(active_tab)) {
      setLoadingMessage("Loading Data...");
      getMyBetsData(
        category,
        active_tab,
        successGetMyBets,
        errorGetMyBets,
        sortCategory,
        next_page_url
      );
    }
  }, [sortCategory,trigger]);

  useEffect(() => {
    if (!isEmpty(active_tab)) {
      setLoadingMessage("Loading Data...");
      getMyBetsData(
        category,
        active_tab,
        successGetMyNewBets,
        errorGetMyBets,
        sortCategory,
        ""
      );
    }
  }, [active_tab, category]);

  const successGetMyBets = (data) => {
    
    if (data?.data?.length == 0) setLoadingMessage("No Data Available");
    let new_bet = data?.data;
    let combined_bets = myBets.concat(new_bet);
    let unique_fixtures;

    if (category != "Spribe") {
       unique_fixtures = combined_bets.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
    } else {
     unique_fixtures = combined_bets.filter(
        (v, i, a) => a.findIndex((t) => t.action_id === v.action_id) === i
      );
    }
    setMyBets(unique_fixtures);
    set_next_page_url(data?.next_page_url);
  };

  const successGetMyNewBets = (data) => {

    if (data?.data?.length == 0) setLoadingMessage("No Data Available");

    setMyBets(data?.data);
    set_next_page_url(data?.next_page_url);
  };
  const errorGetMyBets = (error) => {
    showMessage("error", error);
  };

  useEffect(() => {
    if (myBets.length > 0) {
      setLoadingMessage(null);
    } else {
      setLoadingMessage("No Data Available");
    }

    BlockedGames()
      .then((res) => {
        setIframeURL(res);
      })
      .catch((e) => {
        if (e?.response?.data?.code == "restrict") {
          setShow(true);
          // props.history.push("/comming-soon");
        }
      });
  }, [myBets]);

  const tabs = [
    {
      id: "Open",
      title: "Open",
      activeClass: active_tab == "Open" ? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
    {
      id: "Settled",
      title: "Settled Bets",
      activeClass: active_tab == "Settled" ? "active" : "",
      tabHighlightText: ``,
      tabHighlightId: "",
    },
  ];
  return (
    <>
      <PageHeader title={`${active_tab} Bets`} showBack={false} />
      <TabMenu
        tabs={tabs}
        active_tab={active_tab}
        set_active_tab={setActiveTab}
        dispatch={dispatch}
      />

      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-tabs-content">
            <div className="BLM-filterDropdowns">
              <div className="BLM-select">
                <select
                  id="ddn_mybets"
                  onChange={(e) => {
                    set_category(e.target.value);
                  }}
                >
                  <option value="Sports" selected={category == "Sports"}>
                    Sports
                  </option>
                  <option value="Virtuals" selected={category == "Virtuals"}>
                    Virtuals
                  </option>
                  {show ? (
                    ""
                  ) : (
                    <option value="Games" selected={category == "Games"}>
                      Games
                    </option>
                  )}
                  {
                    (show) ? "" :  <option value="Spribe" selected={category == "Spribe"}>
                      Spribe
                    </option>
                  }
                  <option value="Jackpots" selected={category == "Jackpots"}>
                    Jackpots
                  </option>
                  <option value="Casino" selected={category == "Casino"}>
                    Casino
                  </option>
                </select>
              </div>
              <div className="BLM-select">
                <select
                  id="ddn_mybets_sort"
                  onChange={(e) => {
                    set_sort_category(e.target.value);
                  }}
                >
                  <option value="Latest" selected={sortCategory == "Latest"}>
                    Latest
                  </option>
                  <option value="Oldest" selected={sortCategory == "Oldest"}>
                    Oldest
                  </option>
                </select>
              </div>
            </div>
            {active_tab == "Open" &&
              (![undefined, null, ""].includes(loadingMessage) ? (
                <MessageBox data={loadingMessage} />
              ) : (
                <OpenBets
                  myBets={myBets}
                  isCategory={category == "Sports" ? true : false}
                  next_page_url={next_page_url}
                  set_next_page_url={set_next_page_url}
                  category={category}
                />
              ))}

            {active_tab == "Settled" &&
              (![undefined, null, ""].includes(loadingMessage) ? (
                <MessageBox data={loadingMessage} />
              ) : myBets?.length ? (
                <SettledBets
                  myBets={myBets}
                  next_page_url={next_page_url}
                  set_next_page_url={set_next_page_url}
                  category={category}
                />
              ) : (
                <MessageBox data={`No Data Available`} />
              ))}

            {next_page_url && (
              <div
                onClick={() => {
                  setTrigger(trigger + 1);
                }}
              >
                <MessageBox data={loadMoreMessage} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyBets;
