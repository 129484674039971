import { isEmpty } from "lodash";
import { showMessage } from "../Toaster";
import moment from "moment";
import get from "lodash/get";
import lookup from "country-code-lookup";
import {currency} from "../../../helpers/constants";
import {getUserInfo} from "../../../helpers/repository";

export const getUrlLastPart = () => {
  let url = new URL(location.href);
  if (url["search"] == "") {
    return url["pathname"].split("/").filter(Boolean).pop();
  }
  return url["search"].split("=").pop();
};

export const showErrorMessages = (error) => {
  error?.response?.data?.error &&
    showMessage("error", error?.response?.data?.error);
  error?.response?.data?.message &&
    showMessage("error", error?.response?.data?.message);
  if (error?.response?.data?.errors) {
    let errorsObj = error.response.data.errors;
    for (var key of Object.keys(errorsObj)) {
      errorsObj[key].forEach((arr) => {
        showMessage("error", arr);
      });
    }
  }
};

export const activeClass = (tabLink) => {
  return tabLink == getUrlLastPart() ? "active" : "";
};

export function getFlagEmoji(countryCode) {

  if (["International Clubs", "International",'International Youth'].includes(countryCode))
    return "extra flag-globe";
  if (["Scotland"].includes(countryCode)) return "extra flag-gb-sct";
  if (["USA"].includes(countryCode)) return "extra flag-us";
  if (["Korea Republic"].includes(countryCode)) return "kr";
  if (["Kosovo"].includes(countryCode)) return "xk";
  if (["Wales"].includes(countryCode)) return "gb-wls";
  if (["Ivory Coast"].includes(countryCode)) return "ci";
  if (["England", "England Amateur"].includes(countryCode)) return "gb-eng";
  if (["Bosnia &amp; Herzegovina"].includes(countryCode)) return "ba";
  if (["Congo Republic"].includes(countryCode)) return "cd";
  if (["Palestine"].includes(countryCode)) return "ps";
  
  countryCode = countryCode.replace(" Amateur", "");

  let ob = lookup.byCountry(countryCode);
  if (ob) {
    return ob.iso2.toLowerCase();
  }
  const codePoints = countryCode.toLowerCase();
  return codePoints.substring(0, 2);
}

export const getDayName = (inputDate) => {
  return moment(inputDate).add("day").calendar();
};

export const getParticipantByPosition = (position = "", participants = []) => {
  if (participants.length) {
    return participants.find(
      (participant) => participant?.pivot?.position == position
    );
  }
};

export const oddsInRow = (num) => {
  const conversion = {
    1: "BLM-oneInRow",
    2: "BLM-twoInRow",
    3: "BLM-threeInRow",
  };
  return get(conversion, `${num}`, "");
};

export const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const getSportName = (name = "") => {
  let sn = name.toLowerCase().replaceAll(" ", "-");
  if (sn == "soccer") return "football";
  if (sn == "rugby") return "rugby-league";
  if (sn == "kabaddi") return "kabaddi";
  return sn;
};
export const getLiveSportImgName = (name = "") => {
  let sn = name.toLowerCase().replaceAll(" ", "-");
  if (sn == "rugby") return "rugby-ball";
  if (sn == "kabaddi") return "kabaddi";
  if (sn == "table-tennis") return "tabletennis";
  if (sn == "ice-hockey") return "ice-hockey2";
  if (sn == "specials") return "special";
  if (sn == "esport-league-of-legends") return "esport";
  if (sn == "aussie-rules") return "australian-football";
  return sn;
};

export const getUserBalance = () => {
  // getUserInfo();
  let cash_balance = JSON.parse(localStorage.getItem("userInfo"))?.data?.cash_balance;
  if (cash_balance) return `${currency} ${cash_balance}`;
  return `${currency} 0`;
};

export const getfreebetAmount = () => {
  // getUserInfo();
  let freebetAmount = JSON.parse(localStorage.getItem("userInfo"))?.data
    ?.signup_freebet;
  if (freebetAmount) return `${freebetAmount}`;
  return `0`;
};
