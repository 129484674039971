import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";

export const FixturesJackpotResult = (props) => {
    const { isLive = false } = props;
    let { fixtures,fixtureCount } = props;
    const [leagues, setLeagues] = useState([]);
    const [fixtureDate, setFixtureDateGroup] = useState([]);
    const [exciseTax, setExciseTax] = useState(0);
    const [activeBaseline, setActiveBaseline] = useState(null);
    const [baseLines, setBaseLines] = useState([]);

    fixtures = fixtures.sort(function (a, b) {
        return (
            new Date(parseInt(a.match_date.$date.$numberLong)) -
            new Date(parseInt(b.match_date.$date.$numberLong))
        );
    });

    useEffect(() => {
        let allLeagues = fixtures?.map((f) => f?.tournament?.bb_id);
        allLeagues = [...new Set(allLeagues)];
        var union = allLeagues.filter(function(item){
            return leagues.indexOf(item) === -1;
        });
        var uniqLeague = leagues.concat(union);
        setLeagues(uniqLeague);
    }, [fixtures]);

    const handleFixtureClick = (fixture) => {
        // if (props.isLive) {
        //     history.push(`/fixture-details/live/show?id=${fixture._id}&is_live=1`);
        //     return;
        // }
        // history.push(`/fixture-details/show?id=${fixture._id}`);
    };


    const checkIfOddIsSelected = (f, fm, odd) => {
        return false;
    };

    const _renderDateNames = (secondDate) => {
        var res=fixtures?.find((f) => {
                var firstDate = new Date(parseInt(f.match_date.$date.$numberLong)).toLocaleString("en-GB",{hour12: true, year: 'numeric', month: '2-digit', day: '2-digit' });
                return firstDate===secondDate
            }
        )
        if(res){
            return new Date(parseInt(res.match_date.$date.$numberLong)).toLocaleString("en-GB",{hour12: true, year: 'numeric', month: '2-digit', day: '2-digit' })

        }
        return false;
    };
    const _renderFixtureDateNames = (fdate,secondDate) => {
        var firstDate = new Date(parseInt(fdate)).toLocaleString("en-GB",{hour12: true, year: 'numeric', month: '2-digit', day: '2-digit' });
        if(firstDate===secondDate){
            return true;
        }
        return false;
    };

    const getActiveBaseLineOdds = (odds) => {
        return odds?.filter((o) => o.special_bet_value == activeBaseline);
    };
    const _renderStartingSoon = () => {
        return <button className="btn BLM-btn">Starting Soon</button>;
    };
    const _renderOdds = (f,index) => {
        let odds = f?.fixture_markets[0]?.fixture_market_odds ?? [];
        if (baseLines?.length) {
            odds = getActiveBaseLineOdds(odds ?? []);
        }
        return (
            <>
                <ul>
                    {odds?.map((o,key) => {
                        return (
                            <>
                                <li>
                                    <button
                                        odds-incdec="D"
                                        id="highlightBet_316625476"
                                        key-cnt={o.value != "OFF" ? `${fixtureCount += 1}` : ''}
                                        className={`btn-cls-cnt btn BLM-btnOdds ${
                                            (o?.status==true || o?.is_won==true)  
                                                ? `btn BLM-btnOdds active`
                                                : `btn BLM-btnOdds`
                                        }
                    ${o?.name == "OFF" ? "disabled" : ""} 
                    `}
                                        disabled={o ? (o.value == "OFF" ? true : false) : true}

                                    >
                                        <div className="BLM-marketOutcome">{o?.name}</div>
                                        {o.value == "OFF" ? "-" : parseFloat(o?.value)?.toFixed(2)}
                                    </button>
                                </li>
                            </>
                        );
                    })}
                </ul>
            </>
        );
    };

    const getFixtureMarket = (f) => {
        return f.fixture_markets.find((fm) => fm.market._id == props.marketId);
    };


    const getUniqueBaseLines = (odds) => {
        let base_lines = odds
            .filter((o) => o.special_bet_value && o.special_bet_value != "-1")
            .map((o) => o.special_bet_value);
        return [...new Set(base_lines)];
    };

    const getUniqueArray = (array) => {
        return [...new Set(array)];
    };

    useEffect(() => {
        let baseLines = [];
        let fixtureDateGroup = [];
        fixtures?.forEach((f) => {
            let odds = f?.fixture_markets[0]?.fixture_market_odds ?? [];
            let market = f?.match_date?.$date?.$numberLong
            fixtureDateGroup = fixtureDateGroup.concat(new Date(parseInt(market)).toLocaleString("en-GB",{hour12: true, year: 'numeric', month: '2-digit', day: '2-digit' }));
            let base_lines = getUniqueBaseLines(odds);
            baseLines = baseLines.concat(base_lines);
        });
        baseLines = baseLines?.slice(0, 5);
        baseLines = baseLines?.sort(function (a, b) {
            return a - b;
        });

        fixtureDateGroup = fixtureDateGroup?.sort(function (a, b) {
            return a - b
        });
        setFixtureDateGroup(_.uniq(fixtureDateGroup) ?? [])
        setBaseLines(_.uniq(baseLines) ?? []);

    }, [fixtures]);

    useEffect(() => {
        if (baseLines.length) setActiveBaseline(baseLines[0]);
    }, [baseLines]);
    const _renderBaseLines = () => {
        return (
            <>
                {/*<div*/}
                {/*    className="BLM-subFilterButtons"*/}
                {/*    id="showSubMarketsList"*/}
                {/*    style={{ display: "block" }}*/}
                {/*>*/}
                {/*    <ul className="upcomingsubMarketList">*/}
                {/*        {baseLines?.length > 0*/}
                {/*            ? baseLines.map((bl) => {*/}
                {/*                return (*/}
                {/*                    <>*/}
                {/*                        <li*/}
                {/*                            className={activeBaseline == bl ? "active" : ""}*/}
                {/*                            style={{ cursor: "pointer" }}*/}
                {/*                            onClick={() => {*/}
                {/*                                setActiveBaseline(bl);*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            TG {bl}*/}
                {/*                        </li>*/}
                {/*                    </>*/}
                {/*                );*/}
                {/*            })*/}
                {/*            : null}*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </>
        );
    };

    const _renderMarketNames = () => {
        let odds = fixtures[0]?.fixture_markets[0]?.fixture_market_odds ?? [];
        if (baseLines?.length) {
            odds = getActiveBaseLineOdds(odds ?? []);
        }
        return (
            odds?.map((o) => {
                return (
                    <>
                        <li>
                            <div className="BLM-marketItem">
                                {_extractMarketSpecName(o?.market_spec?.name??'')}
                            </div>
                        </li>
                    </>
                );
            }) ?? null
        );
    };
    const _extractMarketSpecName = (text) => {
        text = text.replaceAll("{$competitor1}", "1");
        text = text.replaceAll("draw", "X");
        text = text.replaceAll("{$competitor2}", "2");
        text = text.replaceAll("{total}", "");
        text = text.replaceAll("odd", "Odd");
        text = text.replaceAll("even", "Even");
        text = text.replaceAll("over", "Over");
        text = text.replaceAll("under", "Under");

        if (text == "u") {
            return "Under";
        } else if (text == "o") {
            return "Over";
        }
        return text;
    };
    return (
        <>
            {_renderBaseLines()}
            <div
                className="BLM-header"
                id="upcoming_1"
                onClick={() => {
                    window.toggleAccoridan(index);
                }}
            >
                <div  style={{width:'77%'}}>
                </div>
                <div className="BLM-leagueBox-header__marketInfo">
                    <div className="BLM-marketGroup">
                        <ul>{_renderMarketNames()}</ul>
                    </div>
                </div>
            </div>

            {fixtureDate?.map((fdate, index) => {
                // if (typeof(league) !== 'undefined' && league != null && fixtures?.find((f) => f.tournament_bb_id == league)) {

                if (typeof(fixtureDate) !== 'undefined' && fixtureDate != null && _renderDateNames(fdate)) {
                    return (

                        <div className="BLM-leagueBox-group" key={index}>

                            <div
                                className="BLM-leagueBox BLM-accordion"
                                id={`getUpcomingAccordianId_${index}`}
                            >
                  {/*              <div*/}
                  {/*                  className="BLM-leagueBox-header BLM-accordion-header BLM-arrowBefore"*/}
                  {/*                  id="upcoming_1"*/}
                  {/*                  onClick={() => {*/}
                  {/*                      window.toggleAccoridan(index);*/}
                  {/*                  }}*/}
                  {/*              >*/}
                  {/*                  <div className="BLM-leagueBox-header__leagueInfo">*/}
                  
                  
                  {/*<span className="BLM-leagueName">*/}
                  {/*  {*/}
                  
                  {/*      // fdate.toLocaleString("en-GB",{ year: 'numeric', month: 'long', day: 'numeric' })*/}
                  {/*      _renderDateNames(fdate)*/}
                  
                  
                  {/*  }                  </span>*/}
                  {/*                  </div>*/}
                  {/*                  <div className="BLM-leagueBox-header__marketInfo">*/}
                  {/*                      <div className="BLM-marketGroup">*/}
                  {/*                          <ul>{_renderMarketNames()}</ul>*/}
                  {/*                          /!*<ul>*!/*/}
                  {/*                          /!*    <li>Home</li>*!/*/}
                  {/*                          /!*    <li>Away</li>*!/*/}
                  {/*                          /!*</ul>*!/*/}
                  {/*                      </div>*/}
                  {/*                  </div>*/}
                  {/*              </div>*/}
                                <div className="BLM-accordion-content">
                                    <div className="BLM-leagueBox-content">
                                        {/* SECOND LOOP */}
                                        {fixtures
                                            .filter((f) => _renderFixtureDateNames(f.match_date.$date.$numberLong,fdate))
                                            .map((fixture) => {
                                                const participantOne = fixture.competitors[0];
                                                const participantTwo = fixture.competitors[1];
                                                if(fixture.competitors && fixture.competitors.length != 0 ) {
                                                    return (
                                                        <div className="BLM-matchBox" id={1} style={{
                                                            borderBottom: "unset"}}>
                                                            <div className="BLM-matchDetails-container">
                                                                <div
                                                                    className="BLM-matchDetails"
                                                                    onClick={() => {
                                                                        handleFixtureClick(fixture);
                                                                    }}
                                                                >
                                                                    <div className="BLM-matchBox__fixtureInfo">
                                                                        <div className="BLM-match__teamsInfo">
                                                                            <div className="BLM-match__teamName home truncate">
                                                                                {participantOne?.name}
                                                                            </div>
                                                                            <div className="BLM-match__teamName away truncate">
                                                                                {participantTwo?.name}
                                                                            </div>
                                                                        </div>
                                                                        <div className="BLM-match__scoreInfo">
                                                                            <div className="BLM-match__score">
                                    <span className="BLM-score active">
                                      {fixture?.score?.split(":")[0] ?? ""}
                                    </span>
                                                                            </div>
                                                                            <div className="BLM-match__score">
                                    <span className="BLM-score active">
                                      {fixture?.score?.split(":")[1] ?? ""}
                                    </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="BLM-outcomeDetails">
                                                                    <div className="BLM-btnOddsGroup">
                                                                        {isLive
                                                                            ? fixture?.bet_status != "started"
                                                                                ? _renderStartingSoon()
                                                                                : _renderOdds(fixture,index)
                                                                            : _renderOdds(fixture,index)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="BLM-matchBox__extraInfo" onClick={() => {
                                                                handleFixtureClick(fixture);
                                                            }}>
   <span
       className="BLM-match__kickOff"
       eventid="home_upcoming_4042486"
   >
                              {new Date(
                                  parseInt(fixture.match_date.$date.$numberLong)
                              ).toLocaleString("en-GB",{hour12: true ,day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit'})}
                            </span>

                            {/*                                    <span className="BLM-match__smsCode">*/}
                            {/*  #<b>{fixture?.bb_id}</b>*/}
                            {/*</span>*/}


                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}

                                        {/* SECOND LOOP */}
                                    </div>
                                </div>
                            </div>

                            {/* <MessageBox /> */}
                        </div>
                    );
                }
            })}
        </>
    );
};
