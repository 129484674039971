import PageHeader from "../../components/Common/PageHeader";
import {
  getFixturesBySportIdCall,
  getJackpotByIdCall,
  getRegulatoryAndParameters,
  placeBetCall
} from "../../helpers/repository";
import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import {FixturesJackpot} from "../../components/Common/FixtureJackpot";
import MessageBox from "../../components/Common/MessageBox";
import {get} from "lodash";
import {useSelector} from "react-redux";
import toastr from "toastr";
import {currency} from "../../helpers/constants";

function RunningJackpot(props) {
  const { id } = useParams()
  const [info, setInfo] = useState(false);
  const [jackpot, setJackpot] = useState(true);
  const [stake, setStake] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const [trigger, setTrigger] = useState(0);
  const [marketChange, setMarketChange] = useState(1);
  const [minimumBet, setMinimumBet] = useState(null);
  const [maximumBet, setMaximumBet] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [noOfEntry, setNoOfEntry] = useState(0);
  const [Bets, setBet] = useState([]);
  const [acceptOddsChange, setAcceptOddsChange] = useState(false);
  const [WHT, setWHT] = useState();
  const [keepBet, setKeepBet] = useState(false);
  var fixtureCount = 0

  useEffect(() => {
    let allLeagues = jackpot?.fixtures?.data?.map((f) => f?.tournament?.bb_id);
    allLeagues = [...new Set(allLeagues)];
    var union = allLeagues.filter(function(item){
      return leagues.indexOf(item) === -1;
    });
    var uniqLeague = leagues.concat(union);
    setLeagues(uniqLeague);
  }, [jackpot?.fixtures?.data]);

  useEffect(() => {
    getRegulatoryAndParameters(handleSuccess);
  }, []);

  const handleSuccessPlaceBet = () => {
    if (!keepBet) {
      setBet([])
      setStake(0)
      setNoOfEntry(0)
    }
    toastr.success(`Bet Placed Successfully`);
  };

  const handleErrorPlaceBet = (error) => {
    toastr.error(error);
  };
  const handleSuccess = (res) => {
    // setStake(parseFloat(res.betting_limits.minimum_bet));
    setMinimumBet(parseFloat(res.betting_limits.minimum_bet));
    setMaximumBet(parseFloat(res.betting_limits.maximum_bet));
    setExciseTaxPercentage(parseFloat(res.regulatory.sales_excise_tax));
    setWHTPercentage(parseFloat(res.regulatory.wht_winning_tax));
  };
  const _handleSubmitBet = () => {
    //check all matches are selected or not
    var divs=document.getElementsByClassName('btn-cls-cnt');
    var totalMatch = divs[divs.length - 1].getAttribute('key-cnt');
    var mustSelectedMatch=totalMatch/3;
    if(Bets?.length<mustSelectedMatch){
      toastr.error(`Please select all entry`);
      return;
    }

    // if (parseFloat(stake) < jackpot?.price_per_ticket) {
    //   toastr.error(`Stake should be Greater Or Equal to ${jackpot?.price_per_ticket}`);
    //   return;
    // }
    if(parseFloat(jackpot?.price_per_ticket) <= 0) {
      toastr.error(`Stake should be greater than zero`);
      return;
    }
    let odds = Bets.map((bet) => {
      return _getPlacedBet(bet.fixture_markets);
    }).map((bet) => {
      let sendBet = {};
      sendBet._id = bet._id;
      sendBet.value = parseFloat(bet.value);
      sendBet.base_line = bet.special_bet_value;
      return sendBet;
    });
    let data = {};
    data.odds = odds;
    data.stake = jackpot?.price_per_ticket;
    data.accept_odds_change = acceptOddsChange;
    data.max_price = jackpot?.max_price;
    data.jackpot_id = id;
    // data.
    placeBetCall(data, handleSuccessPlaceBet, handleErrorPlaceBet);
  };
  const _getPlacedBet = (markets) => {
    for (let i = 0; i < markets.length; i++) {
      let odds = markets[i].fixture_market_odds.filter((o) => o?.selected);
      if (odds.length) {
        return odds[0];
      }
    }
  };

  const fetchData = () => {
    getJackpotByIdCall(id,trigger,marketChange)
        .then((res) => {
          if(trigger!=0){
            let new_fixtures = get(res, "data.data[0].fixtures.data", []);
            let combined_fixtures = jackpot?.fixtures?.data.concat(new_fixtures);
            res.data.data[0].fixtures.data=combined_fixtures;
            setJackpot(jackpot);
          }
          setJackpot(res?.data?.data[0]);
          setMarketChange(res?.data?.data[0]?.marketId)
          if(res?.data?.data[0].length==0){
            setLoadingMessage("No Data Available");
          }
          else{
            setLoadingMessage(null);
          }
        })
  }
  useEffect(() => {
    fetchData();
  }, [trigger]);
  return (
      <>
        <div className="BLM-commonPage">
          <div className="BLM-commonPage-content-full-width BLM-common">
            <div className="NP-jackpot NP-JP-previousResults">
              <div className="BLM-pageHeader">
                <div
                    className="BLM-pageBackButton"
                    onClick={() => {
                      props.history.push(`/jackpot`);
                    }}
                >
                  <div className="BLM-iconSvg">
                    <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 24"
                        style={{ enableBackground: "new 0 0 24 24" }}
                        xmlSpace="preserve"
                    >
                      <path d="M10.4,22.4l1.8-1.8l-7.3-7.3H24v-2.6H5l7.3-7.3l-1.8-1.8L0,12L10.4,22.4z" />
                    </svg>
                  </div>
                </div>
                <div className="BLM-pageHeader-title">Current Jackpot</div>
              </div>
              {/*<PageHeader title={"Current Jackpot"} showBack={true}/>*/}
              <div className="NP-JP-market-group">
                <div className="NP-JP-market-group__header NP-JP-market__header-1x2">
                  <div className="NP-JP-market-group__header-left">
                    <img src="assets/images/SVG/trophy.svg" />
                    <span className="NP-JP-market-header__title">
                    {jackpot?.name}
                  </span>
                  </div>
                  <div className="NP-JP-market-group__header-right"></div>
                </div>
              </div>
              <div className="NP-JP-cardBanner previousResults">
                <div className="NP-JP-cardBanner-container">
                  <div className="NP-accordion NP-prizeMoneyAccordion collapsed">
                    <div className="NP-accordion-header NP-cardBanner__prizeMoney-container">
                      <div className="NP-cardBanner__prizeMoney">
                        <span className="NP-currency">{currency} </span>
                        <span className="NP-prizeValue">
                          {Number(Number(jackpot?.max_price).toFixed(2)).toLocaleString('en-GB')}
                        </span>
                      </div>
                    </div>
                    <div
                        className={`NP-JP-cardBanner-row NP-accordion-content ${
                            info ? "" : "d-none"
                        }`}
                    >
                      <div className="NP-cardBanner__description">
                        Prizes for runners up and most predictions - Jackpot will
                        be shared if more than 1 winner
                      </div>
                    </div>
                  </div>
                  <div className="NP-cardBanner__footer">
                    <div className="NP-cardBanner__bonusExtraInfo">
                      <div className="NP-cardBanner__extraInfo">
                        <span>Market Type:{jackpot?.market}</span>
                        <br />
                        <span>Minimum Stake:{currency} {jackpot?.price_per_ticket}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="BLM-home-upcoming-content">

                {![undefined, null, ""].includes(loadingMessage) ? (
                    <MessageBox data={loadingMessage} />
                ) : jackpot?.fixtures?.data.length ? (
                    <FixturesJackpot
                        fixtures={jackpot?.fixtures?.data}
                        marketId={marketChange}
                        perPage={10}
                        loadingMessage={loadingMessage}
                        loadMoreMessage={loadMoreMessage}
                        fixtureCount={fixtureCount}
                        setStake={setStake}
                        setNoOfEntry={setNoOfEntry}
                        Bets={Bets}
                        setBet={setBet}

                    />

                ) : (
                    <MessageBox
                        data={`No Data Available`}
                    />
                )}
              </div>

              {jackpot?.fixtures?.next_page_url && (
                  <div
                      onClick={() => {
                        setTrigger(trigger + 1);
                      }}
                  >
                    <MessageBox data={loadMoreMessage} />
                  </div>
              )}
              {/* --------------------------- footer --------------------------- */}
              <div className="BLM-betSlip-footer">
                <div className="BLM-stakeBox-container">
                  <div className="BLM-freeBet" />

                  <div id="displayFreeBetDropDown" />
                </div>
                <div className="BLM-totalStakeOddsWins">
                  <div className="BLM-accordion" id="getBetslipAccordianId">
                    <div className="BLM-accordion-header BLM-BetslipArrowAfter removeColor">
                      <div className="BLM-totalStakeOddsWins-content" style={{width: '100%'}}>
                        {/*<div className="BLM-contentBlock font-cls">*/}
                        {/*  <div className="BLM-leftContent">Entry Stake:</div>*/}
                        {/*  <div className="BLM-rightContent">*/}
                        {/*    <select>*/}
                        {/*      <option>Select stake</option>*/}
                        {/*      <option>{jackpot?.price_per_ticket}</option>*/}
                        {/*    </select>*/}
                        {/*    /!*{totalOdds}*!/*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="BLM-contentBlock BLM-wht font-cls">
                          <div className="BLM-leftContent">
                            Total Stake:
                          </div>
                          <div
                              className="BLM-rightContent yellowContent"
                              id="bindDeductedStakeValue"
                          >
                            {currency} {jackpot?.price_per_ticket}
                          </div>
                        </div>
                        {/*<div className="BLM-contentBlock BLM-wht font-cls">*/}
                        {/*  <div className="BLM-leftContent">No. of entries:</div>*/}
                        {/*  <div className="BLM-rightContent yellowContent" id="bindTaxValue">*/}
                        {/*    {jackpot?.price_per_ticket}*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="BLM-placeBet-container p-2">
                  <div id="displayPlaceBetButton">
                    <button
                        className="btn BLM-btnPrimary active BLM-btnLarge BLM-btn BLM-btnPlaceBet"
                        onClick={() => {
                          _handleSubmitBet();
                        }}
                    >
                      <span className="BLM-btnTxt text-light">Place Jackpot Bet</span>
                    </button>
                  </div>{" "}
                  <div id="displayAcceptOddsButton" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default RunningJackpot;
