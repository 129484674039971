import React, {useEffect, useState} from "react";
import PageHeader from "../../components/Common/PageHeader";
import {currency} from "../../helpers/constants";
import {
  callDepositeAmount,
  callWithdrawAmount,
  getRegulatoryAndParameters,
  RestrictModules
} from "../../helpers/repository";
import {showMessage} from "../../components/Common/Toaster";
import {getUserBalance} from "../../components/Common/Utils/helpers";
import ReactModal from "react-modal";

function Withdrawal() {
  const [num, setNumber] = useState('1000');
  const [minimum_withdraw, set_minimum_withdraw] = useState(0);
  const [loading, setLoading] = useState(null);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [openModel, setModelOpen] = useState(false);
  const [isRestrict, setIsRestrict] = useState(false);

  useEffect(() => {
    RestrictModules().then((res) => {
      if(res?.data?.data?.is_enable==0){
        setIsRestrict(true)
      }
      else{
        setIsRestrict(false)
      }
    }).catch((e) => {

    });

  }, []);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px!important",
      // backgroundColor: "#272727",
      color: "black",
      padding: "0px"
    },
  };
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);
  const _handleModalOpen = (e) => {
    setModelOpen(true);
  }
  useEffect(() => {
    getRegulatoryAndParameters(handleSuccess);
  }, []);
  const handleSuccess = (res) => {
    set_minimum_withdraw(res?.betting_limits?.minimum_withdrawal)
  }
  const _handleSubmitWithdraw = (e) => {
    setModelOpen(false);
    setRunTimer((t) => !t)
    setLoading(1)
    var milliseconds = new Date().getTime();
    var randm = Math.floor(100000 + Math.random() * 900000);
    var rndmNo=milliseconds+"_"+randm;
    // console.log(JSON.parse(localStorage.getItem("user")))
    let data = {};
    data.amount=num;
    data.user_id=JSON.parse(localStorage.getItem("user"))?.user?.id;
    data.currency=currency;
    data.mobile_prefix="256";
    data.random_number=rndmNo;
    callWithdrawAmount(data).then((res) => {
      showMessage("success",res?.data?.message)
      // console.log(res)
    }).catch((e) => {
      showMessage("error",e?.response?.data?.error)
    });
  }
  return (
      <>
        <PageHeader title={"Withdraw"} />
        <div className="BLM-commonPage">
          <div className="BLM-commonPage-content BLM-common">
            <div className="space-3"></div>
            <div className="row">
              <p
                  className="text-center
            "
              >
                Your Account Balance: <b className="text-danger">{getUserBalance()}</b>
              </p>
            </div>
            <div className="space-5"></div>

            <div className="row">
              <div className="col-3">
                <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='1000' ? "active" : ''}`}
                        onClick={() => {
                          setNumber('1000');
                        }}>
                  <span className="BLM-btnTxt">1,000</span>
                </button>
              </div>
              <div className="col-3">
                <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='2500' ? "active" : ''}`}
                        onClick={() => {
                          setNumber('2500');
                        }}>
                  <span className="BLM-btnTxt">2,500</span>
                </button>
              </div>
              <div className="col-3">
                <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='7500' ? "active" : ''}`}
                        onClick={() => {
                          setNumber('7500');
                        }}>
                  <span className="BLM-btnTxt">7,500</span>
                </button>
              </div>
              <div className="col-3">
                <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='10000' ? "active" : ''}`}
                        onClick={() => {
                          setNumber('10000');
                        }}>
                  <span className="BLM-btnTxt">10,000</span>
                </button>
              </div>
            </div>
            <div className="space-10"></div>

            <div className="row">
              <div className="col-lg-12">
                <div className="BLM-form-group">
                  <div className="BLM-form-label">
                    Enter Any other Amount to Withdraw (minimum withdrawal {currency} {minimum_withdraw}):
                  </div>
                  <div
                      className="BLM-form-control BLM-form-control-pin"
                      id="errorMessagePassClass"
                  >
                    <input
                        type="text"
                        id="userPass"
                        onkeyup="return numberMobile(event)"
                        onkeypress="return enterUserName(event)"
                        placeholder="XXXX"
                        required
                        pattern="[0-9]*"
                        value={num} onChange={handleChange}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    <span id="displayErrorMessage"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-5"></div>
            {
              isRestrict ?     <button
                      className="btn BLM-btnDanger  BLM-btnLarge" style={{    opacity: "0.5"}}>
                    <span className="BLM-btnTxt">Withdraw</span>
                  </button> :

                  runTimer ?
                      <button
                          className="btn BLM-btnDanger active BLM-btnLarge"
                          id="disableLoginButtonClick">
                        <span className="spinner-grow spinner-grow-sm"></span>
                      </button> :
                      <button
                          className="btn BLM-btnDanger active BLM-btnLarge"
                          id="disableLoginButtonClick"
                          onClick={() => {
                            _handleModalOpen();
                          }}
                      >
                        <span className="BLM-btnTxt">Withdraw</span>
                      </button>


            }





            <div className="space-5"></div>

            <div className="row">
              <div className="col-lg-5"></div>
              <div className="separator col-lg-2">OR</div>
              <div className="col-lg-5"></div>
            </div>
            <div className="space-5"></div>
            <div className="row d-flex">
              <div className="col-md-6 text-center">
                <img src="/assets/images/PNG/airtel-money.png"/>
              </div>
              <div className="col-md-6 text-center">
                <img src="/assets/images/PNG/MTN-money.png"/>
              </div>
              {/* <div className="col-md-4 text-center">
              <img src="/assets/images/PNG/Zam-money.png"/>
            </div> */}

            </div>
            {
              <div className="row img_row">
                <ReactModal
                    isOpen={openModel}
                    style={customModalStyles}
                    // className="modal-content-react"
                >

                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title h4">Withdraw</div>
                      <button type="button" className="btn-close" aria-label="Close" onClick={()=> {
                        setModelOpen(false);
                      }}></button>
                    </div>
                    <div className="modal-body">

                      <p style={{fontSize:"17px"}}>Are you sure you want to withdraw {num} amount?</p>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" style={{padding: "8px 17px"}}  onClick={() => {
                        _handleSubmitWithdraw();
                      }}>Yes</button>
                      <button type="button" className="btn btn-danger" style={{padding: "8px 17px"}}  onClick={() => {
                        setModelOpen(false);
                      }}>Cancel</button>
                    </div>
                  </div>
                </ReactModal>
              </div>
            }


            <div className="space-5"></div>

            <div className="withdrawal-bottom-section">
              <div className="BLM-commonHeader">
                <b className="BLM-commonHeader-title">How to Withdraw</b>
              </div>
            </div>
            <div className="space-5"></div>

            <div>
              {/* <h3 className="BLM-common-mainTitle">
              Via SMS(applies to all networks)
            </h3>
            <ol>
              <li>
                To withdraw, send the work W#Amount ti 29663 <br /> E.g. W#500
                to 29663
              </li>
              <li>
                Your Sakabet account will be deducted and your mobile money
                wallet funded with the amount.
              </li>
            </ol> */}
              <h3 className="BLM-common-mainTitle">
                Via Website(applies to all networks)
              </h3>
              <ol>
                <li>Click on Menu then click on Withdraw</li>
                <li>Chose one of the preselected values or</li>
                <li>
                  Enter the amount you'd like to withdraw and click on Withdraw
                </li>
                <li>
                  Your playable account will be deducted and your mobile money
                  wallet funded with the amount
                </li>
                <li>
                  You will receive a confirmation message once your withdrawal has
                  been successful.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
  );
}

export default Withdrawal;
