import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveFooterTab,
  setActiveTab,
  showSideBar,
} from "../../store/actions";
import Hamburger from "./Hamburger";
import { logoutUser } from "../../store/Auth/actions";
import { withRouter } from "react-router-dom";
import { getSportName, getUserBalance } from "./Utils/helpers";
import { BlockedGames, HeaderMenu } from "../../helpers/repository";
import { getLiveSportImgName, getUrlLastPart } from "./Utils/helpers";
function Header(props) {
  const dispatch = useDispatch();
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const [show, setShow] = useState(false);
  const [headerMenu, setHeaderMenu] = useState([]);
  const Sports = useSelector((state) => state?.Sports.Index ?? []);
  const Products = useSelector((state) => state?.Products.Index ?? []);
  const handleLogout = (e) => {
    e.preventDefault();
    const { history } = props;
    dispatch(logoutUser(history));
  };

  useEffect(() => {
    BlockedGames()
      .then((res) => {
        setIframeURL(res);
      })
      .catch((e) => {
        if (e?.response?.data?.code == "restrict") {
          setShow(true);
          // props.history.push("/comming-soon");
        }
      });
  }, []);

  const active_tab = useSelector(
    (state) => state?.CommonState?.activeTab ?? "home-upcoming"
  );
  let path = props?.location?.pathname.substring(1);
  //
  let dataMenu = {
    Sports: {
      className: `${
        ["home-upcoming", "home-live-now"].includes(active_tab ?? "")
          ? "active"
          : ""
      }`,
      route: () => props.history.push("/home"),
      dispatch: () => dispatch(setActiveTab("home-upcoming")),
      footerActive: "",
    },
    AllSports: {
      className: `${
        ["AllSports", "sports-details", "Football"].includes(active_tab)
          ? "active"
          : ""
      }`,
      route: () => props.history.push("/AllSports"),
      dispatch: () => dispatch(setActiveTab("AllSports")),
      footerActive: "",
    },
    Aviator: {
      className: `${
        ["Aviator", "aviator"].includes(active_tab ?? "") ? "active" : ""
      }`,
      route: () => props.history.push("/aviator"),
      dispatch: () => dispatch(setActiveFooterTab("")),
      footerActive: "",
    },
    Jackpot: {
      className: `${
        ["jackpot", "jackpot-running", "jackpot-upcoming"].includes(
          active_tab ?? ""
        )
          ? "active"
          : ""
      }`,
      route: () => props.history.push("/jackpot"),
      dispatch: () => dispatch(setActiveTab("jackpot")),
      footerActive: () => setActiveFooterTab(""),
    },
    Virtuals: {
      className: `${getUrlLastPart() == "virtual-league" ? "active" : ""}`,
      route: () => props.history.push("/virtual-league"),
      dispatch: () => dispatch(setActiveTab("virtual-league")),
      footerActive: () => dispatch(setActiveFooterTab("")),
    },
    Games: {
      className: `${active_tab == "Games" ? "active" : ""}`,
      route: () => props.history.push("/Games"),
      dispatch: () => dispatch(setActiveTab("Games")),
      footerActive: () => dispatch(setActiveFooterTab("")),
    },
    Promotions: {
      className: `${active_tab == "promotions" ? "active" : ""}`,
      route: () => props.history.push("/promotions"),
      dispatch: () => dispatch(setActiveTab("promotions")),
      footerActive: () => dispatch(setActiveFooterTab("")),
    },
    Slots: {
      className: `${active_tab == "casinos" ? "active" : ""}`,
      route: () => props.history.push("/casinos"),
      dispatch: () => dispatch(setActiveTab("casinos")),
      footerActive: () => dispatch(setActiveFooterTab("")),
    },
    Spribe: {
      className: `${
        ["Spribe", "spribe"].includes(active_tab ?? "") ? "active" : ""
      }`,
      route: () => props.history.push("/spribe"),
      dispatch: () => dispatch(setActiveFooterTab("")),
      footerActive: "",
    },
  };
  //
  return (
    <div className="BLM-layout-header">
      <div className="BLM-mainHeader">
        <div className="BLM-mainHeader-leftSection">
          <div className="BLM-select BLM-selectSideMenu">
            <select
              id="ddFeaturedSidemenu"
              // onChange="getSidemenuFeaturedphone(value)"
            >
              <option value>Menu</option>
              <option value="azsports">All Sport</option>
              <option value="promotions">Promotions</option>
              <optgroup label="Quick Links">
                <option value="smsbetting">SMS Betting</option>
                <option value="howtodeposit">How To Deposit</option>
              </optgroup>
            </select>
          </div>
          <div
            className="hamburger_menu"
            onClick={() => {
              dispatch(showSideBar(true));
            }}
          >
            <Hamburger />
          </div>
          <div
            className="BLM-mainHeader-logo BLM-logo"
            onClick={() => {
              dispatch(setActiveTab("home-upcoming"));
              dispatch(setActiveFooterTab("home"));
              props.history.push("/home");
            }}
          >
            <img src="/assets/images/PNG/new_logo.png" />
          </div>
        </div>
        <div className="BLM-mainHeader-rightSection">
          <div className="BLM-mainHeader-beforeLogin ">
            <div className="BLM-mainHeader-loginRegister">
              {isLoggedIn ? (
                <>
                  <Link to="/Deposits">
                    <span
                      class="BLM-betSlip-userBalance"
                      id="betslipUserBalance"
                    >
                      {[
                        "home",
                        "jackpot",
                        "virtual-league",
                        "Games",
                        "casinos",
                        "MyBets",
                        "Deposits",
                        "promotions",
                        "AllSports",
                      ].includes(path)
                        ? `${getUserBalance()}`
                        : null}
                    </span>
                    <button class="btn BLM-btn BLM-btnSuccess active BLM-btnSmall">
                      <span class="BLM-btnTxt">Deposit</span>
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login">
                    <button className="btn BLM-login BLM-btnSmall">
                      Login
                    </button>
                  </Link>
                  <Link to="/register">
                    <button className="btn BLM-register BLM-btnSmall">
                      Register
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*<div className="BLM-depositMpesa-header">*/}
      {/*  Deposit*/}
      {/*  <div className="BLM-iconSvg">*/}
      {/*    <svg*/}
      {/*      version="1.1"*/}
      {/*      baseProfile="tiny"*/}
      {/*      id="Layer_1"*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*      xmlnsXlink="http://www.w3.org/1999/xlink"*/}
      {/*      x="0px"*/}
      {/*      y="0px"*/}
      {/*      width="24px"*/}
      {/*      height="24px"*/}
      {/*      viewBox="0 0 24 24"*/}
      {/*      xmlSpace="preserve"*/}
      {/*    >*/}
      {/*      <path d="M13.6,1.6l-1.8,1.8l7.3,7.3H0v2.6h19l-7.3,7.3l1.8,1.8L24,12L13.6,1.6z" />*/}
      {/*    </svg>*/}
      {/*  </div>{" "}*/}
      {/*  MPESA Paybill: 238844*/}
      {/*</div>*/}
      <div
        className="BLM-subHeader BLM-scrollableArea BLM-horizontal"
        id="subHeader"
      >
        <ul className="topHeaderAddActiveClass">
          {Products?.map((f) => {
            const foundData = dataMenu[f?.name.replace(/\s+/g, "")];
            if (foundData) {
              return (
                <>
                  <li
                    className={foundData?.className}
                    onClick={() => {
                      foundData?.route();
                      foundData?.dispatch();
                    }}
                  >
                    {f?.name}
                  </li>
                </>
              );
            }
          })}
        </ul>
      </div>
      {/* <div className="BLM-sportsHeader BLM-scrollableArea BLM-horizontal">
        <div
          className="BLM-categoryListItem BLM-inPlay"
          
          data-footer-inplay="In-play"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={{
              enableBackground: "new 0 0 24 24",
            }}
            xmlSpace="preserve"
          >
            <path
              d="M14.8,10.6l-5,3.3c-0.2,0.2-0.6,0-0.6-0.3V7c0-0.3,0.3-0.5,0.6-0.3l5,3.3C15,10.1,15,10.4,14.8,10.6z M22.6,4.8H1.4v10.9
                                                            h21.2V4.8 M22.7,3.4C23.4,3.4,24,4,24,4.7v11.1c0,0.7-0.6,1.3-1.3,1.3H1.3c-0.7,0-1.3-0.6-1.3-1.3V4.7C0,4,0.6,3.4,1.3,3.4H22.7
                                                            L22.7,3.4z M18,20v-0.5c0-0.3-0.3-0.6-0.6-0.6H6.6c-0.3,0-0.6,0.3-0.6,0.6V20c0,0.3,0.3,0.6,0.6,0.6h10.8C17.7,20.6,18,20.3,18,20z
                                                            "
            />
          </svg>
     
        </div>
        {Sports?.data?.map((sport, index) => (
          <>
            <div
              className="BLM-categoryListItem"
              value="australian rules"
              onClick={(e) => {
                props.history.push(`/sports-details?id=${sport.id}`);
                // window.location.href=`/sports-details?id=${sport.id}`;
              }}
              key={index + 1}
            >
              <img
                className="live-sports-img"
                src={`assets/images/sporticons/${getLiveSportImgName(
                  sport?.name
                )}.svg`}
              />
              
            </div>
          </>
        ))}
      </div> */}
    </div>
  );
}

export default withRouter(Header);
