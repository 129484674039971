import ReactModal from "react-modal";
import React, { useEffect, useState } from "react";
import { currency } from "../../helpers/constants";
import FieldValidation from "../../components/Common/FieldValidation";
import {
  axiosApi,
  callDepositeAmount,
  callDepositeAmountThroghModal,
} from "../../helpers/repository";
import Cookies from "js-cookie";
import toastr from "toastr";
import { registerUser } from "../../store/Auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../components/Common/Toaster";
function SignupPopup(props) {
  const dispatch = useDispatch();
  const [openSignupModel, setSignupModelOpen] = useState(props.showSignupModal);
  const [openInfoModel, setInfoModelOpen] = useState(props.showInfoModal);
  const [phone_number, set_phone_number] = useState("");
  const [nin, set_nin] = useState("");
  const [name, set_name] = useState("");
  const [surname, set_surname] = useState("");
  const [amount, set_amount] = useState("");
  const [code, set_code] = useState(null);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [pin, setPin] = useState(null);
  const [sendCodeMessage, setSendCodeMessage] = useState("Send Code");
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);
  useEffect(() => {
    if (openSignupModel) {
      const delay = 50; // Adjust the delay as needed
      if (window.innerWidth > 1600) {
        setTimeout(() => {
          const modalElement = document.querySelector(".ReactModal__Content");
          const existingStyle = modalElement.getAttribute("style");

          const customStyle = `
                                    ${existingStyle || ""}
                                    width: 21% !important;
                                    background: white;
                              `;
          if (modalElement) {
            modalElement.setAttribute("style", customStyle);
          }
        }, delay);
      }
    }
  }, [openSignupModel]);
  useEffect(() => {
    if (openInfoModel) {
      setSignupModelOpen(false);
      const delay = 10; // Adjust the delay as needed
      if (window.innerWidth > 1600) {
        setTimeout(() => {
          const modalElement = document.querySelector(".ReactModal__Content");
          const existingStyle = modalElement.getAttribute("style");

          const customStyle = `
                                    ${existingStyle || ""}
                                    width: 21% !important;
                                    background: white;
                              `;
          if (modalElement) {
            modalElement.setAttribute("style", customStyle);
          }
        }, delay);
      }
    }
  }, [openInfoModel]);
  const sendCode = () => {
    setRunTimer((t) => !t);
    const data = {
      phone_number: phone_number,
      type: "registration",
    };

    axiosApi({
      method: "post",
      url: `/client/verify-code`,
      data: data,
    })
      .then(function (response) {
        setPin(response?.data?.pin);
        setSendCodeMessage("Send Code");
        toastr.success("Code sent successfully");
      })
      .catch(function (response) {
        toastr.error(response?.response?.data?.error);
      });
  };
  const formErrors = useSelector((state) => state?.CommonState);
  useEffect(() => {
    if (formErrors) {
      Object.keys(formErrors).map((key) => {
        if (formErrors[key][0] && formErrors[key][0].length > 5) {
          toastr.error(formErrors[key][0]);
        }
      });
    }
  }, [formErrors]);

  $("#regMobile").on("input", function () {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "");
    }
    if (/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(this.value)) {
      this.value = this.value.replace(
        /[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
    }
  });
  const sendAmount = () => {
    let data = {};
    data.amount = amount;
    data.user_id = phone_number;
    data.currency = currency;
    data.mobile_prefix = "256";
    data.type = 1;

    callDepositeAmountThroghModal(data)
      .then((res) => {
        showMessage("success", res?.data?.message);
        props.setInfoShowModal(false);
        setSignupModelOpen(false);
        setInfoModelOpen(false);
        localStorage.setItem("depositThroughPopup", 1);
      })
      .catch((e) => {
        showMessage("error", e?.response?.data?.error);
      });

    Cookies.set("lastModalTime", new Date().getTime(), { expires: 1 });
  };

  const cancelAmount = () => {
    Cookies.set("lastModalTime", new Date().getTime(), { expires: 1 });
    props.setInfoShowModal(false);
    setSignupModelOpen(false);
    setInfoModelOpen(false);
  };

  const existingUser = () => {
    if (props.dontShowAgain) {
      Cookies.set("dontShowModalAgain", true, { expires: 365 });
    }
    props.setSignupShowModal(false);
  };

  const submitNumber = (e) => {
    const data = {
      phone_number: phone_number,
      nin: nin,
      pin: pin,
      type: 1,
      code: parseInt(code),
      first_name: name,
      last_name: surname,
    };

    dispatch(registerUser(data, props.history, cb));
  };
  const cb = (message) => {
    showMessage("success", message);
    setInfoModelOpen(true);
  };
  // const eb = (message) => {
  //   showMessage("error", message);
  //   // setInfoModelOpen(true);
  // };
  const customBetStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "21% !important",
      backgroundColor: "#272727",
      color: "black",
      padding: "0px",
    },
  };

  return (
    <>
      <div className="row img_row modal-pop-up">
        <ReactModal isOpen={openSignupModel} style={customBetStyles}>
          <div className="modal-content  sign-up-div">
            <div className="modal-header register-header">
              <div className="modal-title h4" style={{ fontWeight: "bold" }}>
                Welcome
              </div>
              <button
                type="button"
                className="btn-close btn-close-white"
                aria-label="Close"
                onClick={() => {
                  setSignupModelOpen(false);
                }}
              ></button>
            </div>
            <div className="modal-body">
              {pin ? (
                <div className="green-div-popup">
                  <p style={{ fontSize: "17px", fontWeight: 600 }}>
                    Enter the verification code
                  </p>
                  <p
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Name and Surname
                  </p>
                </div>
              ) : (
                <div className="green-div-popup">
                  <p style={{ fontSize: "17px", fontWeight: 600 }}>
                    Enter YOUR NUMBER TO GET
                  </p>
                  <p
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {currency} 500 FREE BET
                  </p>
                </div>
              )}

              <div className="BLM-form-group">
                <div className="BLM-form-label">Enter Mobile Number:</div>
                <div
                  className="BLM-form-control-group"
                  id="regErrorMessageClass"
                >
                  <div className="BLM-form-addon">+256</div>
                  <div className="BLM-form-control">
                    <input
                      type="tel"
                      id="regMobile"
                      placeholder="XXXXXXXXX"
                      maxLength={9}
                      minLength={9}
                      required
                      onCopy={(e) => e.preventDefault()} // Disable copying
                      onPaste={(e) => e.preventDefault()} // Disable pasting
                      onChange={(e) => {
                        set_phone_number(e.target.value);
                      }}
                      value={phone_number}
                    />
                  </div>

                  {runTimer ? (
                    <div className="input-group-append">
                      <span className="input-group-text text-prepend-box">
                        <span className="spinner-grow spinner-grow-sm"></span>
                      </span>
                    </div>
                  ) : (
                    <div
                      className="input-group-append"
                      onClick={() => {
                        sendCode();
                      }}
                    >
                      <span className="input-group-text text-prepend-box">
                        Send Code
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {pin ? (
                <>
                  <div className="BLM-form-group" style={{ marginTop: "10px" }}>
                    <div className="BLM-form-label">Verification Code:</div>
                    <div
                      className="BLM-form-control BLM-form-control-pin"
                      id="regPinErrorMessageClass"
                    >
                      <input
                        type="text"
                        id="code"
                        maxLength={6}
                        required
                        onChange={(e) => {
                          set_code(e.target.value);
                        }}
                        value={code}
                      />
                    </div>
                  </div>
                  <div className="BLM-form-group">
                    <div className="BLM-form-label">First Name: </div>
                    <div
                      className="BLM-form-control BLM-form-control-pin"
                      id=""
                    >
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter First Name"
                        required
                        onChange={(e) => {
                          set_name(e.target.value);
                        }}
                        value={name}
                      />
                    </div>
                  </div>
                  <div className="BLM-form-group">
                    <div className="BLM-form-label">Last Name:</div>
                    <div
                      className="BLM-form-control BLM-form-control-surname"
                      id=""
                    >
                      <input
                        type="text"
                        id="surname"
                        placeholder="Enter Last Name"
                        required
                        onChange={(e) => {
                          set_surname(e.target.value);
                        }}
                        value={surname}
                      />
                    </div>
                  </div>
                  <div className="BLM-form-group">
                  <div className="BLM-form-label">NIN:</div>
                  <div
                      className="BLM-form-control BLM-form-control-pin"
                      id=""
                  >
                    <input
                        type="text"
                        id="nin"
                        placeholder="Enter National Identity Number"
                        required
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only uppercase alphabetic characters and digits
                          const regex = /^[A-Z0-9 ]*$/;
                          if (regex.test(value)) {
                            set_nin(value);
                          }
                        }}
                        onPaste={(e) => e.preventDefault()} // Prevent paste
                        maxLength={14}
                        value={nin}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.nin ? (
                        <FieldValidation
                            errorMessage={formErrors?.nin}
                        />
                    ) : null}
                   </div>
                 </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <div className="BLM-form-group">
                  <input
                    type="checkbox"
                    name="never_show_again"
                    checked={props.dontShowAgain}
                    onChange={props.handleDontShowAgainChange}
                    style={{ marginTop: "8px" }}
                  />{" "}
                  <span>Never show again</span>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className={`btn btn-dark btn-popup ${
                    props.dontShowAgain == true
                      ? "enable-popup-btn"
                      : "disable-popup-btn"
                  }`}
                  onClick={(e) => {
                    existingUser();
                  }}
                >
                  I am existing user
                </button>
                <button
                  type="button"
                  className="btn btn-dark btn-popup"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    submitNumber();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal isOpen={openInfoModel} style={customBetStyles}>
          <div className="modal-content  sign-up-div">
            <div className="modal-header register-header">
              <div className="modal-title h4" style={{ fontWeight: "bold" }}>
                Deposit
              </div>
            </div>
            <div className="modal-body">
              <div className="green-div-popup">
                <p style={{ fontSize: "17px", fontWeight: 600 }}>
                  Deposit over UGX 1000 to activate your free bet and get an
                  instant 100% match BONUS
                </p>
              </div>
              <div className="BLM-form-control">
                <input
                  type="text"
                  id="amount"
                  placeholder="Min 1000"
                  maxLength={9}
                  minLength={9}
                  required
                  onChange={(e) => {
                    set_amount(e.target.value);
                  }}
                  value={amount}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-dark btn-popup"
                onClick={(e) => {
                  cancelAmount();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark btn-popup"
                style={{ backgroundColor: "#068c45" }}
                onClick={(e) => {
                  sendAmount();
                }}
              >
                Unlock Bonus
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
}

export default SignupPopup;
