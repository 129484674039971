import startCase from "lodash/startCase";

export function storeTokenToLocalStorage(token) {
  if (token) {
    sessionStorage.setItem("token", token);
  }
}

export function storeUserToLocalStorage(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function storeDeposiThroghPopupToLocalStorage(deposit) {
  localStorage.setItem("depositThroughPopup", JSON.stringify(deposit));
}
export function removeDeposiThroghPopupToLocalStorage(deposit) {
  localStorage.removeItem("depositThroughPopup");
}

export function getTokenFromLocalStorage() {
  return sessionStorage.getItem("token");
}

export function deleteTokenFromLocalStorage() {
  sessionStorage.removeItem("token");
}
export function convertUTCDateToLocalDate(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export const getLeagueNameByLeagueId = (Leagues, id) => {
  if (Leagues) {
    return Leagues.find((league) => league?.bb_id == id)?.name;
  }
};

export const getNoDataMessage = (matchTime) => {
  if (matchTime !== "all") {
    let mc = matchTime.replaceAll("_", " ");
    return ` For ${startCase(mc)}`;
  }
  return "";
};
