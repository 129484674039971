import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { imagePath } from "./constants";

const firebaseConfig = {
  apiKey: "AIzaSyB2BkY4y3pCDmu5hMKmGsJ_9rkjzaiEF84",
  authDomain: "betsportsug-69957.firebaseapp.com",
  projectId: "betsportsug-69957",
  storageBucket: "betsportsug-69957.appspot.com",
  messagingSenderId: "738839587790",
  appId: "1:738839587790:web:b66d6cf7f425038351199f"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  const { title, body, click_action } = payload.data;
  
  const notificationOptions = {
    title: title,
    body: body,
    icon: "/assets/images/PNG/BetsportsLogo.png",
    data: {
      click_action: click_action,
    },
  };

  if (payload.data.image) {
    notificationOptions.image = imagePath("campaigns", payload.data.image);
  }

  if (Notification.permission === "granted") {
    const notification = new Notification("title", notificationOptions);
    notification.onclick = (event) => {
      event.preventDefault(); 
      window.open(notificationOptions.data.click_action, "_blank");
    };
  }
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Service Worker registered with scope:', registration.scope);
  }).catch((err) => {
    console.log('Service Worker registration failed:', err);
  });
}

export { firebaseConfig, messaging, getToken, onMessage };
